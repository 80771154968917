







import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import appendHash from "~/utils/AppendAccessTokenHash"

@Component({
    components: {},
    computed: {},
    metaInfo() {
        return {
            title: `${this.$route.params['lesson_id'] ? 'Edit Lesson' : 'Add Lesson'} - ${this.$route.params['id']}` + `${this.$route.params['lesson_id'] ? (' - ' + this.$route.params['lesson_id']) : ''}`
        }
    }
})
export default class LessonEditor extends Vue {

    link = ''
    courseId = ''

    async created() {
        if (!this.link) {
            this.courseId = this.$route.params['id']
            const lessonId = this.$route.params['lesson_id']
            const section = this.$route.query['section']
            const version = this.$route.query['version']
            let link = ''
            if (lessonId) {
                link = `${process.env.VUE_APP_DOMAIN_PORTAL_TUTOR}/course/edit-course/${this.courseId}/edit-lesson/${lessonId}?noframe=1&aa=1`
            } else {
                link = `${process.env.VUE_APP_DOMAIN_PORTAL_TUTOR}/course/edit-course/${this.courseId}/new-lesson?section=${section}&noframe=1&aa=1`
            }
            if (version)
                link += `&version=${version}`

            const res =  await createRequest('/auth/login-token', 'get').send();
            this.link = appendHash(link, {
                login_token: res.data.login_token
            });

        }
    }

    mounted() {
        window.addEventListener('message', this.handleMessage, false)
    }

    beforeDestroy() {
        window.removeEventListener('message', this.handleMessage)
    }

    handleMessage(event) {
        // console.log(event.data.name)
        switch (event.data.name) {
            case 'saveLesson':
                this.$router.replace(`/course/edit-course/${this.courseId}?tab=content`)
                break
        }
    }

}
